

















import Vue from 'vue'
import Component from 'vue-class-component'
import {BProgress, BProgressBar} from "bootstrap-vue";
import {Prop} from 'vue-property-decorator';

Component.registerHooks(['validations'])
@Component({
  components: {BProgress, BProgressBar}
})

export default class SygniProgressBar extends Vue {
  @Prop({ default: 'capitalization' }) type: 'simple' | 'capitalization' | 'inline';
  @Prop() value: number;
  @Prop() max: number;

  get progressBarLabel() {
    if(this.type == 'simple') return '';

    if(this.value == 0 && this.max == 0) return '0%'

    const progress = Math.round((this.value / this.max) * 100)

    return `${progress <= 100 ? progress : 100}%`;
  }
  
  get progressBarSimpleLabel() {
    if(this.value == 0 && this.max == 0) return '0%'

    const progress = Math.round((this.value / this.max) * 100)
  
    return `${progress <= 100 ? progress : 100}%`;
  }

  formatK(n: number) {
    if (n < 1e3) return n;
    if (n >= 1e3) {
      let number: string | number = +(n / 1e3).toFixed(0);
      number = number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")
      return number + " K"
    }
  }

  setCurrency(value: number) {
    return this.formatK(value);
  }
}
