var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "sygni-progress-bar"
  }, [_vm.type == 'capitalization' ? _c('div', {
    staticClass: "sygni-progress-bar__header"
  }, [_c('span', [_vm._v("CAPITALIZATION")]), _c('span', [_vm._v(_vm._s("TARGET ".concat(_vm.setCurrency(_vm.max))))])]) : _vm._e(), _vm.type == 'simple' ? _c('div', {
    staticClass: "sygni-progress-bar__header"
  }, [_c('div', {
    staticClass: "text-center"
  }, [_c('span', {
    staticClass: "text-nowrap"
  }, [_vm._v(_vm._s(_vm.progressBarSimpleLabel))])])]) : _vm._e(), _c('b-progress', {
    attrs: {
      "max": _vm.max,
      "value": _vm.value,
      "variant": ""
    }
  }, [_c('b-progress-bar', {
    attrs: {
      "value": _vm.value,
      "label": _vm.progressBarLabel
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }