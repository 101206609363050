var render = function () {
  var _vm$campaignData, _vm$campaignData2, _vm$campaignData3, _vm$activeUserContext, _vm$activeUserContext2, _vm$activeUserContext3, _vm$translation, _vm$campaignData4, _vm$campaignData5, _vm$campaignData6, _vm$translation2, _vm$translation3, _vm$campaignData7, _vm$translation4, _vm$translation5, _vm$campaignData8, _vm$campaignData9, _vm$campaignData10, _vm$translation6, _vm$campaignData11, _vm$translation7, _vm$translation8, _vm$duration, _vm$duration2, _vm$campaignData12, _vm$campaignData12$pr, _vm$campaignData12$pr2, _vm$translation9, _vm$campaignData13, _vm$campaignData13$pr, _vm$campaignData13$pr2, _vm$campaignData13$pr3, _vm$translation10, _vm$campaignData14, _vm$campaignData14$pr, _vm$campaignData14$pr2, _vm$campaignData14$pr3, _vm$translation11, _vm$campaignData15, _vm$campaignData15$da, _vm$campaignData15$da2, _vm$campaignData16, _vm$campaignData16$da, _vm$campaignData17, _vm$campaignData17$at, _vm$campaignData18, _vm$campaignData18$da, _vm$campaignData18$da2, _vm$campaignData19;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "lp"
  }, [_c('div', {
    class: ['lp-thumbnail', (_vm$campaignData = _vm.campaignData) !== null && _vm$campaignData !== void 0 && _vm$campaignData.imagePath ? '' : 'empty']
  }, [(_vm$campaignData2 = _vm.campaignData) !== null && _vm$campaignData2 !== void 0 && _vm$campaignData2.imagePath ? _c('div', {
    staticClass: "lp-thumbnail__photo"
  }, [_c('img', {
    attrs: {
      "src": (_vm$campaignData3 = _vm.campaignData) === null || _vm$campaignData3 === void 0 ? void 0 : _vm$campaignData3.imagePath,
      "alt": ""
    }
  })]) : _vm._e(), _c('div', {
    staticClass: "lp-thumbnail__bar"
  }, [_c('div', {
    staticClass: "lp-thumbnail__badge"
  }, [(_vm$activeUserContext = _vm.activeUserContextData) !== null && _vm$activeUserContext !== void 0 && _vm$activeUserContext.logo ? _c('img', {
    attrs: {
      "src": (_vm$activeUserContext2 = _vm.activeUserContextData) === null || _vm$activeUserContext2 === void 0 ? void 0 : _vm$activeUserContext2.logo,
      "alt": ""
    }
  }) : _c('div', {
    staticClass: "char"
  }, [_vm._v(_vm._s((_vm$activeUserContext3 = _vm.activeUserContextData) === null || _vm$activeUserContext3 === void 0 ? void 0 : _vm$activeUserContext3.name.slice(0, 1)))])]), _c('div', {
    staticClass: "lp-thumbnail__action"
  }, [_c('sygni-rect-button', {
    staticClass: "filled gn-secondary",
    on: {
      "click": function click($event) {
        return _vm.$emit('invite');
      }
    }
  }, [_vm._v(_vm._s((_vm$translation = _vm.translation) !== null && _vm$translation !== void 0 && _vm$translation.inviteInvestor ? _vm.translation.inviteInvestor : 'Invite investor'))])], 1)])]), _c('div', {
    staticClass: "lp-details"
  }, [_c('div', {
    staticClass: "lp-container"
  }, [_c('div', {
    class: ['lp__row', !_vm.description ? 'column' : '']
  }, [_c('div', {
    staticClass: "lp__col"
  }, [_c('h1', {
    staticClass: "lp-title"
  }, [_vm._v(" " + _vm._s(_vm.headline ? _vm.headline : (_vm$campaignData4 = _vm.campaignData) === null || _vm$campaignData4 === void 0 ? void 0 : _vm$campaignData4.campaignName) + " ")]), _vm.description ? _c('p', {
    staticClass: "lp-desc"
  }, [_vm._v(" " + _vm._s(_vm.description) + " ")]) : _vm._e()]), _c('div', {
    class: ['lp__col', !_vm.description ? 'half' : '']
  }, [_c('sygni-container-title', {
    staticClass: "black"
  }, [_c('p', {
    staticClass: "lp-details__label"
  }, [_vm._v(" " + _vm._s((_vm$campaignData5 = _vm.campaignData) !== null && _vm$campaignData5 !== void 0 && _vm$campaignData5.campaignName ? (_vm$campaignData6 = _vm.campaignData) === null || _vm$campaignData6 === void 0 ? void 0 : _vm$campaignData6.campaignName : (_vm$translation2 = _vm.translation) === null || _vm$translation2 === void 0 ? void 0 : _vm$translation2.campaignDetails) + " ")])]), _c('div', {
    staticClass: "lp-details__row"
  }, [_c('div', {
    staticClass: "lp-details__col"
  }, [_c('p', {
    staticClass: "lp-details__label"
  }, [_vm._v(" " + _vm._s((_vm$translation3 = _vm.translation) !== null && _vm$translation3 !== void 0 && _vm$translation3.target ? _vm.translation.target.toUpperCase() : 'TARGET') + " ")]), _c('p', {
    staticClass: "lp-details__value"
  }, [_c('strong', [_vm._v(_vm._s(_vm._f("thousandSeparator")((_vm$campaignData7 = _vm.campaignData) === null || _vm$campaignData7 === void 0 ? void 0 : _vm$campaignData7.investmentValue)) + " PLN")])])]), _vm.daysLeft !== null ? _c('div', {
    staticClass: "lp-details__col"
  }, [_c('p', {
    staticClass: "lp-details__label"
  }, [_vm._v(" " + _vm._s((_vm$translation4 = _vm.translation) !== null && _vm$translation4 !== void 0 && _vm$translation4.daysLeft ? _vm.translation.daysLeft.toUpperCase() : 'DAYS LEFT') + " ")]), _c('p', {
    staticClass: "lp-details__value"
  }, [_c('strong', [_vm._v(_vm._s(_vm.daysLeft))])])]) : _vm._e()]), _c('div', {
    staticClass: "lp-details__row full"
  }, [_c('div', {
    staticClass: "lp-details__col"
  }, [_c('div', {
    staticClass: "lp-details__label"
  }, [_vm._v(" " + _vm._s((_vm$translation5 = _vm.translation) !== null && _vm$translation5 !== void 0 && _vm$translation5.progress ? _vm.translation.progress.toUpperCase() : 'PROGRESS') + " ")]), _c('sygni-progress-bar', {
    attrs: {
      "value": (_vm$campaignData8 = _vm.campaignData) === null || _vm$campaignData8 === void 0 ? void 0 : _vm$campaignData8.investmentReach,
      "max": (_vm$campaignData9 = _vm.campaignData) !== null && _vm$campaignData9 !== void 0 && _vm$campaignData9.investmentValue ? (_vm$campaignData10 = _vm.campaignData) === null || _vm$campaignData10 === void 0 ? void 0 : _vm$campaignData10.investmentValue : 0,
      "type": "inline"
    }
  })], 1)]), _c('div', {
    staticClass: "lp-details__row"
  }, [_c('div', {
    staticClass: "lp-details__col"
  }, [_c('p', {
    staticClass: "lp-details__label"
  }, [_vm._v(" " + _vm._s((_vm$translation6 = _vm.translation) !== null && _vm$translation6 !== void 0 && _vm$translation6.investmentReach ? _vm.translation.investmentReach.toUpperCase() : 'RAISED FUNDS') + " ")]), _c('p', {
    staticClass: "lp-details__value"
  }, [_c('strong', [_vm._v(_vm._s(_vm._f("thousandSeparator")((_vm$campaignData11 = _vm.campaignData) === null || _vm$campaignData11 === void 0 ? void 0 : _vm$campaignData11.investmentReach)) + " PLN")])])]), _c('div', {
    staticClass: "lp-details__col"
  }, [_c('p', {
    staticClass: "lp-details__label"
  }, [_vm._v(" " + _vm._s((_vm$translation7 = _vm.translation) !== null && _vm$translation7 !== void 0 && _vm$translation7.remainingValue ? _vm.translation.remainingValue.toUpperCase() : 'REMAINING FUNDS') + " ")]), _c('p', {
    staticClass: "lp-details__value"
  }, [_c('strong', [_vm._v(_vm._s(_vm._f("thousandSeparator")(_vm.remainingValue)) + " PLN")])])]), _c('div', {
    staticClass: "lp-details__col"
  }, [_c('p', {
    staticClass: "lp-details__label"
  }, [_vm._v(" " + _vm._s((_vm$translation8 = _vm.translation) !== null && _vm$translation8 !== void 0 && _vm$translation8.duration ? _vm.translation.duration.toUpperCase() : 'DURATION') + " ")]), _c('p', {
    staticClass: "lp-details__value"
  }, [_c('strong', [_vm._v(_vm._s((_vm$duration = _vm.duration) === null || _vm$duration === void 0 ? void 0 : _vm$duration.amount))]), _vm._v(" " + _vm._s((_vm$duration2 = _vm.duration) === null || _vm$duration2 === void 0 ? void 0 : _vm$duration2.label) + " ")])]), ((_vm$campaignData12 = _vm.campaignData) === null || _vm$campaignData12 === void 0 ? void 0 : (_vm$campaignData12$pr = _vm$campaignData12.products[0]) === null || _vm$campaignData12$pr === void 0 ? void 0 : (_vm$campaignData12$pr2 = _vm$campaignData12$pr.data) === null || _vm$campaignData12$pr2 === void 0 ? void 0 : _vm$campaignData12$pr2.simpleOptions) !== null ? [_c('div', {
    staticClass: "lp-details__col"
  }, [_c('p', {
    staticClass: "lp-details__label"
  }, [_vm._v(" " + _vm._s((_vm$translation9 = _vm.translation) !== null && _vm$translation9 !== void 0 && _vm$translation9.interestPA ? _vm.translation.interestPA.toUpperCase() : 'INTEREST P.A.') + " ")]), _c('p', {
    staticClass: "lp-details__value"
  }, [_c('strong', [_vm._v(_vm._s((_vm$campaignData13 = _vm.campaignData) === null || _vm$campaignData13 === void 0 ? void 0 : (_vm$campaignData13$pr = _vm$campaignData13.products[0]) === null || _vm$campaignData13$pr === void 0 ? void 0 : (_vm$campaignData13$pr2 = _vm$campaignData13$pr.data) === null || _vm$campaignData13$pr2 === void 0 ? void 0 : (_vm$campaignData13$pr3 = _vm$campaignData13$pr2.simpleOptions) === null || _vm$campaignData13$pr3 === void 0 ? void 0 : _vm$campaignData13$pr3.interest))]), _vm._v(" % ")])]), _c('div', {
    staticClass: "lp-details__col"
  }, [_c('p', {
    staticClass: "lp-details__label"
  }, [_vm._v(" " + _vm._s((_vm$translation10 = _vm.translation) !== null && _vm$translation10 !== void 0 && _vm$translation10.interestPayment ? _vm.translation.interestPayment.toUpperCase() : 'INTEREST PAYMENT') + " ")]), _c('p', {
    staticClass: "lp-details__value"
  }, [_c('strong', [_vm._v(_vm._s(_vm.getInterestPaymentLabel((_vm$campaignData14 = _vm.campaignData) === null || _vm$campaignData14 === void 0 ? void 0 : (_vm$campaignData14$pr = _vm$campaignData14.products[0]) === null || _vm$campaignData14$pr === void 0 ? void 0 : (_vm$campaignData14$pr2 = _vm$campaignData14$pr.data) === null || _vm$campaignData14$pr2 === void 0 ? void 0 : (_vm$campaignData14$pr3 = _vm$campaignData14$pr2.simpleOptions) === null || _vm$campaignData14$pr3 === void 0 ? void 0 : _vm$campaignData14$pr3.interestPayment)))])])])] : _vm._e()], 2), _c('div', {
    staticClass: "lp-details__action"
  }, [_c('sygni-rect-button', {
    staticClass: "filled gn-secondary"
  }, [_vm._v(" " + _vm._s((_vm$translation11 = _vm.translation) !== null && _vm$translation11 !== void 0 && _vm$translation11.invest ? _vm.translation.invest : 'Invest') + " ")])], 1)], 1)])])]), (_vm$campaignData15 = _vm.campaignData) !== null && _vm$campaignData15 !== void 0 && (_vm$campaignData15$da = _vm$campaignData15.data) !== null && _vm$campaignData15$da !== void 0 && (_vm$campaignData15$da2 = _vm$campaignData15$da.galleryPhotos) !== null && _vm$campaignData15$da2 !== void 0 && _vm$campaignData15$da2.length ? _c('div', {
    staticClass: "lp-gallery"
  }, [_c('div', {
    staticClass: "lp-gallery__heading"
  }, [_c('div', {
    staticClass: "lp-section-title"
  }, [_c('div', {
    staticClass: "lp-section-title__name lp-title"
  }, [_vm._v("Galeria")]), _c('div', {
    staticClass: "lp-section-title__actions"
  }, [_c('sygni-square-button', {
    class: ['outline gn-secondary prev', _vm.navigationDisabledState.start ? 'disabled' : ''],
    on: {
      "click": _vm.handlePrevSlide
    }
  }, [_c('img', {
    attrs: {
      "src": _vm._f("getIcon")('ARROW'),
      "alt": ""
    }
  })]), _c('sygni-square-button', {
    class: ['outline gn-secondary next', _vm.navigationDisabledState.end ? 'disabled' : ''],
    on: {
      "click": _vm.handleNextSlide
    }
  }, [_c('img', {
    attrs: {
      "src": _vm._f("getIcon")('ARROW'),
      "alt": ""
    }
  })])], 1)]), _c('div', {
    staticClass: "lp-gallery__container"
  }, [_c('swiper', {
    ref: "gallerySwiper",
    attrs: {
      "options": _vm.swiperComponentOptions
    },
    on: {
      "transitionEnd": _vm.updateNavigationState
    }
  }, _vm._l((_vm$campaignData16 = _vm.campaignData) === null || _vm$campaignData16 === void 0 ? void 0 : (_vm$campaignData16$da = _vm$campaignData16.data) === null || _vm$campaignData16$da === void 0 ? void 0 : _vm$campaignData16$da.galleryPhotos, function (photo) {
    return _c('swiper-slide', {
      key: photo.id
    }, [_c('div', {
      staticClass: "lp-gallery__photo"
    }, [_c('img', {
      attrs: {
        "src": photo === null || photo === void 0 ? void 0 : photo.filePath,
        "alt": ""
      }
    })])]);
  }), 1)], 1)])]) : _vm._e(), _vm._l(_vm.textSections, function (section, index) {
    return _c('div', {
      key: index,
      staticClass: "lp-text-section"
    }, [_c('div', {
      staticClass: "lp-section-title"
    }, [_c('div', {
      staticClass: "lp-section-title__name lp-title"
    }, [_vm._v(_vm._s(section.title))])]), _c('div', {
      staticClass: "lp-text-section__container"
    }, [_c('div', {
      staticClass: "lp__row"
    }, [_c('div', {
      staticClass: "lp__col"
    }, [_c('div', {
      staticClass: "lp-text-section__content",
      domProps: {
        "innerHTML": _vm._s(section.leftContent)
      }
    })]), (section === null || section === void 0 ? void 0 : section.type) === '2-col' ? _c('div', {
      staticClass: "lp__col"
    }, [_c('div', {
      staticClass: "lp-text-section__content",
      domProps: {
        "innerHTML": _vm._s(section.rightContent)
      }
    })]) : _vm._e()])])]);
  }), (_vm$campaignData17 = _vm.campaignData) !== null && _vm$campaignData17 !== void 0 && (_vm$campaignData17$at = _vm$campaignData17.attachments) !== null && _vm$campaignData17$at !== void 0 && _vm$campaignData17$at.length ? _c('div', {
    staticClass: "lp-documents"
  }, [_c('div', {
    staticClass: "lp-section-title"
  }, [_c('div', {
    staticClass: "lp-section-title__name lp-title"
  }, [_vm._v(" " + _vm._s((_vm$campaignData18 = _vm.campaignData) !== null && _vm$campaignData18 !== void 0 && (_vm$campaignData18$da = _vm$campaignData18.data) !== null && _vm$campaignData18$da !== void 0 && (_vm$campaignData18$da2 = _vm$campaignData18$da.sectionNames[_vm.selectedLanguage]) !== null && _vm$campaignData18$da2 !== void 0 && _vm$campaignData18$da2.documents ? _vm.campaignData.data.sectionNames[_vm.selectedLanguage].documents : 'Documents') + " ")])]), _c('div', {
    staticClass: "lp-documents__container"
  }, _vm._l((_vm$campaignData19 = _vm.campaignData) === null || _vm$campaignData19 === void 0 ? void 0 : _vm$campaignData19.attachments, function (attachment) {
    return _c('sygni-file-box', {
      key: attachment.id,
      attrs: {
        "showPreview": true,
        "icon": "DOWNLOAD"
      }
    }, [_vm._v(" " + _vm._s(attachment.title) + " ")]);
  }), 1)]) : _vm._e()], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }