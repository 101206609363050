



















































































































































































import Vue from 'vue';
import Component from 'vue-class-component';
import SygniRectButton from '@/components/buttons/SygniRectButton.vue';
import SygniContainerTitle from '@/components/layout/SygniContainerTitle.vue';
import SygniProgressBar from '@/components/layout/SygniProgressBar.vue';
import SygniSquareButton from '@/components/buttons/SygniSquareButton.vue';
import SygniFileBox from '@/components/layout/SygniFileBox.vue';
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import 'swiper/css/swiper.css';
import { Prop } from 'vue-property-decorator';
import { Campaign } from '../modules/campaigns/store/types';
import { mapState } from 'vuex';
import { UserContext } from '@/modules/genprox/models/User';
import * as dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import { create, all } from 'mathjs'

const math = create(all);

dayjs.extend(duration);

@Component({
  components: { SygniRectButton, SygniContainerTitle, SygniProgressBar, SygniSquareButton, SygniFileBox, Swiper, SwiperSlide },
  computed: {
    ...mapState('genprox', {
      userContexts: (state: any) => state.userContexts,
      activeUserContextId: (state: any) => state.activeUserData.context?.id
    }),
  },
})
export default class LandingPage extends Vue {
  @Prop({ default: null }) campaignData: Campaign;
  userContexts!: UserContext[];
  activeUserContextId!: string;
  selectedLanguage: string = 'PL';
  swiperComponentOptions: any = {
    loop: false,
    slidesPerView: 'auto',
    spaceBetween: 15,
  }

  navigationDisabledState: { start: boolean, end: boolean } = {
    start: true,
    end: false,
  }

  get translation() {
    let translation = this.$store.getters['campaigns/translations'][this.selectedLanguage]

    if (!translation) {
      translation = this.$store.getters['campaigns/translations'].EN
    }

    return translation
  }

  get remainingValue() {
    if (this.campaignData?.investmentValue) {
      const reached = math.bignumber(this.campaignData?.investmentReach)
      const target = math.bignumber(this.campaignData?.investmentValue)
      const remaining = math.number(math.subtract(target, reached))

      return remaining >= 0 ? remaining : 0
    }

    return 0
  }

  getInterestPaymentLabel(label: string) {
    let value: string = this.campaignData?.data?.translations[this.selectedLanguage].interestPaymentOptions[label];

    return value ? value : label;
  }

  updateNavigationState() {
    if ((this.$refs.gallerySwiper as any).$swiper) {
      this.navigationDisabledState.start = (this.$refs.gallerySwiper as any).$swiper.isBeginning;
      this.navigationDisabledState.end = (this.$refs.gallerySwiper as any).$swiper.isEnd;
    }
  }
    
  handlePrevSlide() {
    if ((this.$refs.gallerySwiper as any).$swiper) {
      (this.$refs.gallerySwiper as any).$swiper.slidePrev();
    }
  }

  handleNextSlide() {
    if ((this.$refs.gallerySwiper as any).$swiper) {
      (this.$refs.gallerySwiper as any).$swiper.slideNext();
    }
  }

  get activeUserContextData() {
    return this.userContexts.find((el: UserContext) => el.id === this.activeUserContextId);
  }

  get languages() {
    return this.campaignData?.data?.languages ? this.campaignData.data.languages : ['PL'];
  }

  get headline() {
    return this.campaignData?.data?.headline[this.selectedLanguage] ? this.campaignData.data.headline[this.selectedLanguage] : '';
  }

  get description() {
    return this.campaignData?.data?.description[this.selectedLanguage] ? this.campaignData.data.description[this.selectedLanguage] : '';
  }

  get textSections() {
    return this.campaignData?.data?.textSections[this.selectedLanguage] ? this.campaignData.data.textSections[this.selectedLanguage] : [];
  }

  get daysLeft() {
    if(!this.campaignData?.endDate) {
      return null;
    }

    const start = new Date();
    const end = new Date(this.campaignData?.endDate);
    const difference = (end.getTime() - start.getTime()) / (1000 * 3600 * 24);

    return (difference > 0) ? Math.ceil(difference) : 0;
  }

  get duration () {
    if (!this.campaignData?.products[0]?.data?.fixedRepaymentDate) {
      if (this.campaignData?.products[0]?.data?.duration) {
        let amount = this.campaignData.products[0].data?.duration?.count
        let translationKey = 'one'
        let label = ''

        if (this.campaignData.products[0].data?.duration?.type?.toUpperCase() === 'YEAR') {
          amount = amount * 12
        }

        if (amount % 10 === 2 || amount % 10 === 3 || amount % 10 === 4) {
          translationKey = 'multiple'
        }

        const ends = String(amount).slice(String(amount).length - 2, String(amount).length)

        if (amount !== 1 && (ends == '12' || ends == '13' || ends == '14' || amount % 10 === 5 || amount % 10 === 6 || amount % 10 === 7 || amount % 10 === 8 || amount % 10 === 9 || amount % 10 === 0)) {
          translationKey = 'other'
        }

        switch (this.campaignData.products[0].data?.duration?.type?.toUpperCase()) {
          case ('YEAR'):
            label = this.translation?.durations?.month[translationKey]
            break
          case ('QUARTER'):
            label = this.translation?.durations?.quarter[translationKey]
            break
          case ('MONTH'):
            label = this.translation?.durations?.month[translationKey]
            break
          case ('DAY'):
            label = this.translation?.durations?.day[translationKey]
            break
        }

        return {
          amount,
          label
        }
      }

      return null
    }

    const start = new Date()
    const end = new Date(this.campaignData?.products[0]?.data?.fixedRepaymentDate)
    const difference = (end.getTime() - start.getTime()) / (1000 * 3600 * 24)

    let months = dayjs.duration(difference, 'days').asMonths()
    months = Math.round(months * 100) / 100

    return {
      amount: `${String(months).replace('.', ',')}`,
      label: months === 1 && months % 1 === 0 ? this.translation?.dates?.one : months % 1 !== 0 ? this.translation?.dates?.decimal : this.translation?.dates?.multiple
    }
  }
}
